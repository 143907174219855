



























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class CarSlotAmountIndicatorComponent extends Vue {
    @Prop({ default: 0 })
    public amount!: number;

    @Prop({ default: null })
    public requiredAmount!: number | null;

    @Prop({ default: false })
    public isTooltipStyleActivated!: boolean;

    get tooMuch(): boolean {
        return !!(this.requiredAmount && this.requiredAmount < this.amount);
    }

    get perfect(): boolean {
        return !!(this.requiredAmount && this.requiredAmount === this.amount);
    }

    get iconColor(): string {
        if (this.tooMuch) {
            return 'error';
        } else if (this.perfect) {
            return 'success';
        } else if (this.isTooltipStyleActivated) {
            return 'white';
        } else {
            return 'primary'
        }
    }
}
